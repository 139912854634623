.homepage {
    margin: auto;
    margin-top:20px;
    background-color:white;
    width:950px;
    height:90%;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.homepage h1 {
    font-size: 24px;
}

.homepage h2 {
    font-size: 24px;
    margin-left:30px;

}

.homepage h3 {
    font-size: 20px;
    margin-left:30px;
    opacity:.7;
}

.servicelist {
    height:300px
}

.servicelist ul {
    margin-left:36px;
    height:100%;
    list-style-type: square;
}

.servicelist li {
    float: left;
    width: 50%;
}

.services h4 {
    margin-left:30px;
}


.homepage p {
    margin-left:30px;
    margin-right:30px;
}

.singleTest i { 
    width:10px;
    margin-left:auto;
}

.singleTest i:hover { 
    cursor: pointer;
}

.singleTest {
    display: flex;
    width:90%;
    justify-content:center;
    align-items: center;
    margin:auto;
}
