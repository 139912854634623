.gallery {
    margin: auto;
    margin-top:20px;
    background-color:white;
    width:950px;
    min-height:900px;
    display:flex;
    flex-direction: column;
}

.gallery h2 {
    font-size: 20px;
    margin-left:30px;
}

.photos {
    display:flex;
    height:100%;
    width:100%;
    flex-wrap:wrap;
}

.photo {
    display: flex;
    margin-left:30px;
    width: 15%;
}

.photo img {
    margin:15px;
    width: 100%;
}

.photo img:hover {
    cursor: pointer;
}

