.references {
    margin: auto;
    margin-top:20px;
    background-color:white;
    width:950px;
    display:flex;
    flex-direction: column;
}

.references h1 {
    font-size: 24px;
}

.references h2 {
    font-size: 20px;
    margin-left:30px;
}

.references p {
    margin-left:30px;
    margin-right:30px;
}

.singleTest a { 
    width:10px;
    margin-left:auto;
}

.singleTest {
    display: flex;
    width:90%;
    justify-content:center;
    align-items: center;
    margin:auto;
}


.references table, th, td{
    border-bottom: 1px solid #ddd;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    padding: 5px
}

.references tr:hover {
    background-color: #f5f5f5;
}