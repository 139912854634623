.contactUs {
    margin:30px;
}
.contactUs input {
    margin-left:10px;
    margin-bottom: 5px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
} 

.contactUs hr {
    border-top:1px black;
    width: 90%
}

.contactUs h2 {
    align-self: flex-start;
    font-size: 20px;
    margin-left:30px;
}


.contactUs textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
    margin-bottom: 5px
  }