.roofing {
    margin: auto;
    margin-top:20px;
    background-color:white;
    width:950px;
    min-height:900px;
    display:flex;
    flex-direction: column;
}

.roofing h1 {
    font-size: 24px;
}

.roofing h2 {
    font-size: 20px;
    margin-left:30px;
}

.roofing p {
    margin-left:30px;
    margin-right:30px;
}
