.navbar {
    display:flex;
    flex-direction: column;
    margin:5px;
}


.navbar h1 {
    font-size: 24px;
    margin:10px
}

.headerimage {
    margin:auto;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
    background-color: black;
}

.navlist {
    margin:auto;
    margin-top: 0;
    width:940px;
    background-color: black;
    height:50px;
    text-decoration: none;
    display:flex;
    align-items: center;
    padding:0;
}

.navlist ul {
    list-style: none;
}

.navlist li {
    padding:0;
    text-decoration: none;
    height:100%;
    display:flex;
    font-size:13px;
    margin:13px;
    color: silver;
    text-decoration: none;
    font-family: "Helvetica Neue", Arial, Helvetica, "Nimbus Sans L", sans-serif
}


.navlist a{
    font-size:13px;
    margin:14px;
    color: silver;
    text-decoration: none;
    font-family: "Helvetica Neue", Arial, Helvetica, "Nimbus Sans L", sans-serif
}

.navlist a:hover {
    color:white
}