.footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.footer h4 {
    margin-left:30px;
}

.footer hr {
    border-top:5px black;
    width: 90%
}